import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import { authHeader } from "src/utils/authHeader";
import {   QuestionType } from "src/interface";
import { ModalAttachment } from "src/components";

export const HomeworksOfStudent = () => {
  document.title = "Курсы | EasyENT";
  const [homework, setHomework] = useState<any>([]);
  const [homework_correct, setHomework_correct] = useState<any>({});
  const params = useParams();
  const location: any = useLocation();
  const [attachamentModalActive, setAttachamentModalActive] = useState(false);
  const [attachmentPath, setAttachmentPath] = useState<string | null>(null); 

  useEffect(() => {
    axios
      .get(
        `/api/v1/homework/by_lesson_and_user/${params?.id}-${params?.user_id}`,
        { headers: authHeader() }
      )
      .then((response) => {
        setHomework(response.data);
      });
  }, [homework_correct]);

  const onChangeChoiceValue = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const homeworkId = element.getAttribute("data-homework-id");
    const homeworkIdnumber  = parseInt("" + homeworkId);
    const is_correct = element.getAttribute("data-correct");
    const myobj = {
      "is_correct": is_correct
    };
    axios
      .put("/api/v1/homework/" + homeworkIdnumber, myobj, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          setHomework_correct(myobj);
        }
      })
  };
  const openAttachmentModal = (attachmentPath: string) => {
    setAttachamentModalActive(true);  
    setAttachmentPath(attachmentPath); 
  };
  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/teacher">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/teacher/course_${location?.state?.course.id}`}>
            {location?.state?.course
              ? location?.state?.course.name
              : "загрузка"}
          </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link
            to={`/teacher/course_${params?.course_id}/homeworks_by_lesson/${params?.id}`}
          >
            {location?.state?.lesson
              ? location?.state?.lesson.name
              : "загрузка"}
          </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>
            {location?.state?.user
              ? location?.state?.user?.full_name
              : "загрузка"}
          </span>
        </li>
      </ul>
      <div className="box">
        <div className="teacherhw-top">
          <h1 className="teacherhw-top__heading">Домашнее задание</h1>
        </div>
        <ul className="q-teacher">
          {homework?.sort((a: any, b: any) => a?.sequence - b?.sequence)
          .map((question: any, index: any) => (
            <li key={question?.question?.id}>
              <div className="question-admin">
                <div className="question-admin__heading">
                  <div className="question-admin__text">
                    <div>
                      {index + 1 + ")"} {question?.question?.display_name}
                    </div>
                  </div>
                  {question?.question?.image !== null ? (
                    <div className="realadmin-choice-row__img">
                      <img
                        src={
                          "https://app.easyent.kz" + question?.question?.image
                        }
                      ></img>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {question?.question?.["question_type"] &&
                question?.question?.["question_type"] == QuestionType.choice ? (
                  <div className="question-row__area">
                    {question?.question?.["question_choise"] !== undefined &&
                    question?.question?.["question_choise"]["values"] !==
                      undefined &&
                    question?.question?.["question_choise"]["values"].length >
                      0 ? (
                      question?.question?.["question_choise"]["values"]
                        .sort((a: any, b: any) => a?.seq_idx - b?.seq_idx)
                        .map((choice: any) => (
                          <div key={choice.id} className="admin-choice-row">
                            <div className="admin-choice">
                              {question?.value == choice.value ? (
                                <div className="tipo-input">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                              ) : (
                                <div
                                  data-sequence={index + 1}
                                  data-value={choice.value}
                                  data-quesiton-id={"" + question?.question?.id}
                                  className="tipo-input"
                                ></div>
                              )}
                              <div className="admin-choise__right">
                                <label
                                    htmlFor={"" + choice.id}
                                    className={
                                       choice.value == question.question.answer ? "input-radio__correct" : question?.value == choice.value ? "input-radio__incorrect" : ""
                                    }
                                  >
                                    {choice.value}
                                </label>
                              </div>
                            </div>
                            {choice.image !== null ? (
                              <div className="adminreal-choice-row__img">
                                <img
                                  src={"https://app.easyent.kz/" + choice.image}
                                ></img>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                    ) : (
                      <div>пусто</div>
                    )}
                  </div>
                ) : 
                <div>
                  {question.attachment !== null ? (
                      <div className="task_attachment-top">
                        <div className="att-images">
                          {question.attachments.length && question.attachments.map((attachment:any,index:number) => (
                            <div className="att-images__item" key={attachment[index]}>
                              <img
                                onClick={() => openAttachmentModal(attachment)}
                                src={"https://app.easyent.kz" + attachment}
                                alt="Homework attachment"
                              />
                            </div>
                            )  
                          )}
                        </div>
                        <form>
                          <div className="true-false">  
                            <div className="true-false__area">  
                              <div onClick={onChangeChoiceValue} data-correct="true" data-homework-id={question?.id} className="tipo-input">
                                  <i className={question?.check_date && question?.is_correct == true ? "fa-solid fa-check" : "fa-solid"}></i>
                              </div>
                              <p>Верно</p>
                            </div>
                            <div className="true-false__area">  
                              <div onClick={onChangeChoiceValue} data-correct="false" data-homework-id={question?.id} className="tipo-input">
                                  <i className={question?.check_date && question?.is_correct == false ? "fa-solid fa-check" : "fa-solid"}></i>
                              </div>
                              <p>Не верно</p>
                            </div>
                          </div>    
                        </form>
                      </div>
                    ) : ""
                  } 
                </div>
                
                
                }
              </div>
            </li>
          ))}
        </ul>
        {attachamentModalActive && attachmentPath !== null && (
          <ModalAttachment
            active={attachamentModalActive}
            attachment={attachmentPath} 
            setActive={setAttachamentModalActive}
          />
        )}
      </div>
    </div>
  );
};
