import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import toast from "src/utils/toast";
import axios from "src/utils/axios";
import moment from "moment";
import { useAppSelector } from "src/hooks/redux";
import { authHeader } from "src/utils/authHeader";
import { Preloader } from "src/components";
import "./TestsPage.scss";
import { BarChart } from '@mui/x-charts/BarChart';

interface IOption {
  value: string;
  label: string;
  id?: number;
  lang?: string;
  type?: string;
  parents?: IOption[] | any;
  children?: IOption[] | any;
}
interface FilteredTestProcessItem {
  title: string;
  subject_id: number;
  results: [number];
}
interface IOptionMonth {
  value: string;
  label: string;
}
interface Test {
  subject_id: number;
  write_date: string; // Add write_date here
  subject: {
    title: string;
  };
}

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    height: "60px",
    background: styles.isDisabled ? "hsl(228deg 6% 85%)" : styles.background,
  }),
};


export const TestsPage = () => {
  document.title = "Тесты | EasyENT";
  const navigate = useNavigate();
  const user = useAppSelector((store) => store.user.data);
  const [activeProcess, setActiveProcess] = useState<any>();
  const [allProcess, setAllProcess] = useState<Array<any>>([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const [loadingAll, setAllLoading] = useState(false);
  const [subjects, setSubjects] = useState<{ value: string; label: string }[]>([]);
  const [oneSubject, setOneSubject] = useState<IOption | null>(null);
  const [tests, setTests] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<IOptionMonth | null>(null);
 
  function getActiveProcess() {
    setActiveLoading(true);
    axios
      .get("/api/v1/tests/process/active", { headers: authHeader() })
      .then((res: any) => {
        setActiveProcess(res.data);
      })
      .finally(() => setActiveLoading(false));
  }
  
  function getAllProcess() {
    setAllLoading(true);
    axios
      .get("/api/v1/tests/process/?skip=0&limit=1000", {
        headers: authHeader(),
      })
      .then((res: any) => {
        setAllProcess(res.data);
      })
      .finally(() => setAllLoading(false));
  }

  useEffect(() => {
    getActiveProcess();
    getAllProcess();
    
    if (user && user.user_feeds) {
      const filterConditions = [
        { course_id: 5955, subject_id: 1, title: "История Казахстана" }, 
        { course_id: 6035, subject_id: 5, title: "Мат.Грамотность" },
        { course_id: 6643, subject_id: 10, title: "Математика" },
        { course_id: 6396, subject_id: 23, title: "Физика" },
        { course_id: 6155, subject_id: 29, title: "Химия" },
        {course_id: 6767, subject_id: 48, title: "Биология" },
        {course_id: 6275, subject_id: 91, title: "География" },
        {course_id: 7886, subject_id: 110, title: "Английский язык" },
        {course_id: 6520, subject_id: 100, title: "Всемирная история" },
        {course_id: 8014, subject_id: 121, title: "Информатика" },
      ];
      const uniqueSubjectsMap = new Map();
      const filteredSubjects = user.user_feeds.flatMap(feed => {
        const matchedCondition = filterConditions.find(
          condition => condition.course_id === feed.course_id
        );
        if (matchedCondition && !uniqueSubjectsMap.has(feed.course_id)) {
          uniqueSubjectsMap.set(feed.course_id, {
            value: "" + matchedCondition.subject_id,
            label: matchedCondition.title,
          });
        }
      });
      setSubjects(Array.from(uniqueSubjectsMap.values()));
  
      console.log("Filtered Subjects:", filteredSubjects);
    }
  }, []);
  
  function startTest() {
    let subject_id = 0;
    if(oneSubject?.value){
      subject_id = parseInt(oneSubject?.value);
    }
    const selectedTest = tests.find((test: any) => test.subject_id === subject_id);
    const existingTest = filteredTestProcess.find(item => item.subject_id === subject_id);
    console.log(existingTest);
    if (!selectedTest) {
      toast("Test not found for the selected subject.", "warning");
      return;
    }
    if (existingTest && existingTest.results.some((result) => result > 0)) {
      toast("У вас уже есть тест за сентябрь по Истории Казахстана", "warning");
      return;
    }
    const test_id = selectedTest.id;
    axios
      .post(
        "/api/v1/tests/process/",
        {
          is_full: false,
          tests: [
              {
                "id": test_id
              }
            ]  
        },
        { headers: authHeader() }
      )
      .then(() => {
        navigate("/tests/ent");
      });
  }

  let filtertest = [];
  useEffect(() => {
    setAllLoading(true);
    axios
      .get("/api/v1/tests/tests/?limit=50", {
        headers: authHeader(),
      })
      .then((res: any) => {
        filtertest = res.data.reduce(function(filtered:any, option:any) {
          const writeDate = option.write_date.slice(0, 7);
          if (writeDate === selectedMonth?.value) {
             const write_date = "" + option.write_date.slice(0,10); 
             const someNewValue = { id: option.id, label: write_date, subject_id: option.subject_id,}
             filtered.push(someNewValue);
          }
          return filtered;
        }, []);
        setTests(filtertest);
        console.log(filtertest);
      })
      
      .finally(() => setAllLoading(false));
      
  }, [selectedMonth]);
    
  const MonthObjects:IOptionMonth[] = [
    {
        "label": "Сентябрь",
        "value": "2024-09",
    },
    {
      "label": "Октябрь",
      "value": "2024-10",
    }
  ]  
  const filteredTestProcess = allProcess.reduce((acc: FilteredTestProcessItem[], process) => {
    process.tests.forEach((test: Test) => {
      if (test.write_date && test.write_date.startsWith("2024-09")) {
        const resultValue = process.result?.[test.subject_id] || 0;
  
        // Only process tests with results greater than 0
        if (resultValue > 0) {
          const existingSubject = acc.find(
            (item: FilteredTestProcessItem) => item.subject_id === test.subject_id
          );
  
          if (existingSubject) {
            existingSubject.results.push(resultValue);
          } else {
            acc.push({
              title: test.subject.title,
              subject_id: test.subject_id,
              results: [resultValue],
            });
          }
        }
      }
    });
    return acc;
  }, [] as FilteredTestProcessItem[]);
  
  console.log(filteredTestProcess);
  const series = filteredTestProcess.map(item => ({
    data: item.results,   
    label: item.title,          
    id: `subject${item.subject_id}` 
  })); 
  const xLabels = [
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
  ];
  console.log(oneSubject);
  return (
    <div className="tests-page">
      <div className="box" style={{ marginBottom: 36 }}>
        <h1 className="title">Тесты по ЕНТ</h1>
        <div className="flex">
          {loadingActive ? (
            <Preloader />
          ) : activeProcess?.id ? (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  navigate("/tests/ent");
                }}
              >
                Открыть активный тест
              </button>
            </>
          ) : (
            <>
              <Select
                value={selectedMonth}
                options={MonthObjects}
                onChange={(option: any) => {
                  setSelectedMonth(option);
                }}
                placeholder="Выберите Месяц"
                className="select"
                styles={selectStyles}
              />
              <Select
                value={oneSubject}
                options={subjects}
                onChange={setOneSubject}
                placeholder="Предмет"
                className="select"
                styles={selectStyles}
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (
                    (oneSubject || tests.length > 1 ) 
                  ){  
                    return startTest();
                  }
                  toast("Выберите поля", "warning");
                }}
              >
                Начать тестирование
              </button>
            </>
          )}
        </div>
      </div>
    
      
      <h1 className="title">Мои График тестов</h1>
      {loadingAll ? (
        <Preloader />
      ) : (
        <div>
          {
            filteredTestProcess.length > 0 ? (
              <div className="box">
                <BarChart
                  width={1000}
                  height={500}
                  series={series}
                  xAxis={[{ data: xLabels, scaleType: 'band' }]}
                />
              </div>
            ) : ""
          }
          
          <div className="mytest">
            <div className="mytest__top">
              <div className="mytest__id">Id</div>
              <div className="mytest__subject">Предметы</div>
              <div className="mytest__date">Дата завершения</div>
              <div className="mytest__result">Результат</div>
            </div>
            {allProcess &&
              allProcess?.map((process) => (
                <div className="mytest__row" key={process.id}>
                  <div className="mytest__id">{process.id}</div>
                  <div className="mytest__subject">
                    {process.tests?.length
                      ? process.tests
                          ?.map((test: any) => test?.subject?.title)
                          .join(", ")
                      : "–"}
                  </div>
                  <div className="mytest__date">
                    {process.end_time
                      ? moment
                          .utc(process.end_time)
                          .local()
                          .format("DD.MM.YYYY HH:mm")
                      : "–"}
                  </div>
                  <div className="mytest__result">
                    {process.result && process.tests?.length === 1 ? (
                      <Link to="/tests/ent" state={{ processId: process?.id }}>
                        {process.tests
                          ?.map(
                            (test: any) =>
                              `${test.subject.title}: ${
                                process.result?.[test.subject_id]
                              }`
                          )
                          ?.join(", ")}
                      </Link>
                    ) : process.result && process.tests?.length > 1 ? (
                      <Link to="/tests/ent" state={{ processId: process?.id }}>
                        {process.tests
                          ?.map(
                            (test: any) =>
                              `${test.subject.title}: ${
                                process.result?.[test.subject_id]
                              }`
                          )
                          ?.join(", ")
                          .concat(
                            `, Общий балл: ${Object.values(
                              process.result
                            )?.reduce((acc: number, cur: any) => acc + cur, 0)}`
                          )}
                      </Link>
                    ) : (
                      "–"
                    )}
                  </div>
                </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
