import axios from "axios";
import React, { useState, FC } from "react";
import { authHeader } from "src/utils/authHeader";
import Select from 'react-select';
import { ICourse } from "src/interface";

export const ModalGeneratePenalty: FC<{active:boolean; setActive:(value:boolean) => void; courses:ICourse[] }> = 
  ({active, setActive, courses}) => {
    const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
    const generatePenalty = () => {
      axios
        .post(`/api/v1/penalty/by_admin?course_id=${selectedCourse}`, {} , {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 200) {
            alert('Пенальи cгенерирован');
          }
        })
    }
   return (
    <div className={active ? "modal active" : "modal"} onClick={()=> setActive(false)}>
      <div className="modal__content" onClick={e => e.stopPropagation()}>
        <div className="modal__heading">Генерация пенальти</div>
        <div className="modal-row">
          <Select
              options={[
                { value: null, label: "Все курсы" },
                ...courses.map((course) => ({
                  value: course.id,
                  label: course.name,
                })),
              ]}
              onChange={(option: any) => setSelectedCourse(option ? option.value : null)}
              isClearable
          />
        </div>
        <div onClick={()=> generatePenalty()} className="btn btn-primary">Выполнить</div>
      </div>
    </div>
  )
}
