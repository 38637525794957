import React, { useEffect, useState } from "react";
import axios from "axios";
import { AdminUser } from "src/interface";
import { Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";
import "./AdminUsersPage.scss";

export const AdminUsersPage = () => {
  document.title = "Пользователи Admin | EasyENT";
  const [users, setAllUsers] = useState<AdminUser[]>([]);
  const [limit] = useState(300);
  const [page, setPage] = useState(1); 
  const [totalUsers] = useState(5000);
  const [search_text, setSearch_text] = useState<string>();
  const [search_email, setSearch_email] = useState<string>();
  const [search_pricelist, setSearch_pricelist] = useState<number | undefined>(); // New state for pricelist
  const [godFilter, setGodFilter] = useState<boolean>(false);

  useEffect(() => {
    const skip = (page - 1) * limit;
    axios
      .get(`/api/v1/users/?skip=${skip}&limit=${limit}`, { headers: authHeader() })
      .then((response:any) => setAllUsers(response.data));
  }, [page, limit]);

  const totalPages = Math.ceil(totalUsers / limit);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch_text(e.target.value);
    axios
      .get(`/api/v1/users/?full_name=${e.target.value}`, { headers: authHeader() })
      .then((response:any) => setAllUsers(response.data));
  };

  const onChangeSearchEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch_email(e.target.value);
    axios
      .get(`/api/v1/users/?email=${e.target.value}`, { headers: authHeader() })
      .then((response:any) => setAllUsers(response.data));
  };

  const onChangeSearchPricelist = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pricelistValue = e.target.value ? parseInt(e.target.value) : undefined;
    setSearch_pricelist(pricelistValue);
    if (pricelistValue) {
      axios
        .get(`/api/v1/users/?skip=0&limit=500&pricelist=${pricelistValue}`, { headers: authHeader() })
        .then((response:any) => setAllUsers(response.data));
    }
  };

  const showYearStudent = () => {
    axios
      .get("/api/v1/users/?skip=0&limit=3000&exist_feed_for_month=2024-09-12", { headers: authHeader() })
      .then((response) => {
        const filteredUsers = response.data.filter((user:any) => user.status_student === true);
        setAllUsers(filteredUsers);
      });
  };

  return (
    <div>
      <h1 className="title">Пользователи</h1>
      <div className="white-row box">
        <div className="white-div">
          <span>ФИО:</span>
          <input className="white-div__input" onChange={onChangeSearchText} value={search_text || ""} type="text" />
        </div>
        <div className="white-div">
          <span>Почта:</span>
          <input className="white-div__input" onChange={onChangeSearchEmail} value={search_email || ""} type="text" />
        </div>
        <div className="white-div">
          <span>Прайслист:</span>
          <input className="white-div__input" onChange={onChangeSearchPricelist} value={search_pricelist || ""} type="number" />
        </div>
        <div className="white-div">
          <span className="opacity">0</span>
          <button className={godFilter ? "btn btn-primary" : "btn"} onClick={() => (
            showYearStudent(),
            setGodFilter(true)
            )}>Все Ученики</button>
        </div>
        <div className="white-div">
          <span className="opacity">0</span>
          <button className={godFilter ? "btn btn-primary" : "btn"} onClick={() => (
            showYearStudent(),
            setGodFilter(true)
            )}>1 Поток</button>
        </div>
        <div className="white-div">
          <span className="opacity">0</span>
          <button className={godFilter ? "btn btn-primary" : "btn"} onClick={() => (
            showYearStudent(),
            setGodFilter(true)
            )}>2 Поток</button>
        </div>
      </div>
      <div>
        Количество учеников: {users.length}
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: '40px' }}>Id</th>
            <th style={{ width: '220px' }}>Фио</th>
            <th style={{ width: '180px' }}>Почта</th>
            <th style={{ width: '140px' }}>Номер телефона</th>
            <th style={{ width: '80px' }}>Язык</th>
            <th style={{ width: '90px' }}>Подписка</th>
            <th style={{ width: '130px' }}>Управление</th>
          </tr>
          {users.map((user) => (
            <tr key={user.id}>
              <td style={{ width: '40px' }}>{user.id}</td>
              <td style={{ width: '220px' }}>{user.full_name}</td>
              <td style={{ width: '180px' }}>{user.email}</td>
              <td style={{ width: '140px' }}>{user.phone}</td>
              <td style={{ width: '80px' }}>{user.lang}</td>
              <td style={{ width: '90px' }}>{user.status_student ? (user.status_student === true ? "Ученик" : "Отказался от курсов") : "Пользователь"}</td>
              <td style={{ width: '130px' }}>
                <div className="item-control">
                  <Link to={`users/edit/${user.id}`} className="item-control__item">
                    <i className="fa-solid fa-pen"></i>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button onClick={handlePrevPage} disabled={page === 1}>Предыдущая</button>
        <span>{page} из {totalPages}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>Следующая</button>
      </div>
    </div>
  );
};
