export interface IPriseItem {
  id: string;
  awardPlace: string;
  name: string;
  image: string;
}
export interface IProductItem {
  id: string;
  name: string;
  course_name?: string;
  image?: string;
  cost?: number;
  price?: number;
}
export enum QuestionType {
  choice = "choice",
  task = "text_area",
}
export interface ILesson {
  id?: number;
  start_date?: Date;
  end_date?: Date;
  available?: boolean;
  name?: string;
  image?: string;
  course?: { name?: string };
  description?: string;
  video_url?: string;
  zoom_link?: string;
  conspected?: string;
  lesson_type?: string;
  homework_visible?: boolean;
  course_id?: number;
  lesson_number?: number;
  question_count?: number;
  correct_homework_percent?:number;
  questions?: IQuestion[];
}
export interface AddLesson {
  course_id: number;
  name?: string;
  start_date?: string;
  end_date?: string;
  lesson_type: string,
  lesson_number?:number;
  time_code: [],
  homework_visible: boolean,
  questions: [],
}
export interface ISubjectItem {
  id: number;
  title: string;
  type: string;
  lang: string;
}
export interface ISubjectLiteItem {
  title: string;
}

export interface ITeacherItem {
  id: number;
  name: string;
  subjects: ISubjectLiteItem[];
  image: string;
  is_curator: boolean | null;
}
export interface ISubscriptions {
  id: number;
  active: boolean;
  name: string;
  courses: { id: number; name: string }[];
  packetType: string;
  subscriptionPeriod: string;
  cost: number;
}

export interface IPenalty {
  id: number;
  course_id: number;
  lesson_id: number;
  user_id: number;
  cancel_reason?: string;
  created_at: Date;
  updated_at: Date;
  updated_by: number;
  user?: {
    full_name: string;
  }
}
export interface ICourse {
  id: number;
  name?: string;
  description?: string;
  price?: number;
  teacher_id?: number;
  subject_id?: number;
  lang?: string;
  type?: string;
  subject?: any;
  duration_month?: number;
  tg_chat_id?: string;
  photo_url?: string;
  teacher?: ITeacherItem;
  lesson_count?: number;
  lessons?: ILesson[];
  available?: true;
  is_express?: true;
  active?:boolean;
  without_penalty?:boolean | undefined;
  penalties?: IPenalty[];
  penalty_count?: number;
}

export enum PackageCourse {
  standart = "standard",
  premium = "premium",
}

export interface Order {
  total_amount?: number;
  start_date?: string;
  end_date?: string;
  is_full?: boolean;
  status?: string;
  package?: PackageCourse;
  positions: [
    {
      course_id?: number;
      name?: string;
      priceStandart?: number;
      pricePremium?: number;
      start_date?: string;
      end_date?: string;
    }
  ];
}

export interface BuyOrder {
  start_date?: string;
  end_date?: string;
  is_full?: boolean;
  status?: string;
  pricelist_id: number;
  package?: string;
  positions?: [
    {
      course_id?: number;
      start_date?: string;
      end_date?: string;
    }
  ] | any;
  referral_code?: string | null;
}

export interface newOrder {
  id: number;
  total_amount: number;
  start_date: string;
  end_date: string;
  is_full?: boolean;
  status?: string;
  package?: PackageCourse;
  user: {
    id: number;
    email: string;
    full_name:string;
    phone?: string;
    pricelist:number;
  }
}


export interface OrderShort {
  id: number;
  total_amount: number;
  start_date?: string;
  end_date?: string;
  is_full?: boolean;
  status?: string;
  package?: string;
  positions: Position[];
}

interface Position {
  order_id: number;
  course_id: number;
  start_date: string;
  end_date: string;
  course: {
    id: number;
    name: string;
  }
}
export interface TeacherHomeworkCourses {
  course_id: number;
  name?: string;
  applied_homeworks: number;
  complete_homeworks: number;
}

export interface TeacherHomeworkByCourse {
  lesson_id: number;
  lesson_name?: string;
  student_id?: string;
  student_name?: string;
}

export interface TeacherHomeworkByLessonAndUser {
  question_id: number;
  question: {
    lesson_id: number;
    display_name?: string;
    question_type: string;
  };
  attachment?: string;
  value: string;
}
export interface AdminUser {
  email: string;
  phone: string;
  full_name: string;
  id: number;
  coins: number;
  is_active: boolean;
  is_superuser: boolean;
  is_teacher: boolean;
  is_parent: boolean;
  lang?: string;
  course_price?: number;
  pricelist?: number | null;
  user_feeds: User_feeds[];
  zoom_account?: string;
  status_student?: boolean | null;
}
export interface LeaderUser {
  user_id: number;
  phone: string;
  full_name: string;
  coins: number;
  status_student?: boolean | null;
}

export interface HomeworkUser {
  user_id: number;
  full_name?: string;
  max_id?: number | null;
} 
export interface User_feeds {
  user_id?: number;
  course_id: number;
  course_name?: string;
  start_date: string;
  end_date: string;
  status: string;
  package?: string;
  is_express?: boolean;
}

export interface AdminQuestionsList {
  lesson_id: 0;
  display_name: string;
  id: 0;
}

export interface AdminAddQuestion {
  id?: number;
  lesson_id: number;
  sequence: number;
  display_name: string;
  answer: string;
  solution?: string;
  question_type: QuestionType;
  question_choise?: any;
}
export interface AdminQuestion_choice {
  name: string;
  value: string;
  seq_idx?: number;
  is_correct: boolean;
  solution?: string;
}

export interface IQuestion {
  display_name?: string;
  id: number;
  image?: string;
  answer?: string;
  solution?: string;
  solution_image?: string;
  sequence?: number;
  question_type?: QuestionType;
  question_choise_id?: number;
  question_choise?: {
    id?: number;
    values?: [
      {
        id?: number;
        name?: string;
        seq_idx?: number;
        value?: string;
        image?: string;
      }
    ];
  };
  homeworks?: [
    {
      user_id?: number;
      value?: string;
      check_date?: string;
      write_date?: Date;
      is_correct?: boolean;
      attachment?: string;
      attachments?: any;
    }
  ];
}

export interface IChoice {
  id?: number;
  name?: string;
  seq_idx?: number;
  value?: string;
  image?: string;
}

export interface AdminLesson {
  id?: number;
  name?: string;
  start_date?: Date;
  end_date?: Date;
  zoom_link?: string;
  video_url?: string;
  conspected?: string;
  course_id?: number;
  lesson_number?: number;
  course_name?: string;
}

export interface ITestsSubjectItem {
  id?: number;
  title?: string;
  image?: string;
  link?: {
    to?: string;
    state?: any;
  };
  buttonTitle?: string;
}
export interface ITestsSubjectItem {
  id?: number;
  title?: string;
  image?: string;
  link?: {
    to?: string;
    state?: any;
  };
  buttonTitle?: string;
}


export interface StudentStatus {
  value: boolean | null;
  label: string;
}
export interface packageOptions {
  value: string;
  label: string;
}
export interface SalesOption {
  value: number;
  label: string;
}
export interface PriceListOption {
  value: number;
  label: string;
}
export interface courseTypeOption {
  value: string;
  label: string;
}
export interface lessonTypeOption {
  value: string;
  homework_visible: boolean;
  label: string;
}
export interface update_user {
  course_price: number;
  status_student: boolean | null;
  pricelist: number;
}

export interface orderStatus {
  value: string;
  label: string;
}

export interface OverallHomework {
  lesson_id?:number;
  course_id: number;
  name?: string;
  question_count?: number;
  homework_is_correct_count?: number,
  homework_is_correct_percent?: number;
}
export interface Pricelist {
  name: string;
  id: number;
  positions: PricelistPosition[];
}
interface PricelistPosition {
  is_express: boolean;
  price: number;
  course_count: number;
  pricelist_id: number;
  start_date: string; // or Date if you want to use Date objects
  end_date: string;   // or Date if you want to use Date objects
  id: number;
}

