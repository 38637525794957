import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { IPenalty, ICourse,  ILesson, User_feeds, OverallHomework } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { MyLessonItem } from "src/components";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useAppSelector } from "src/hooks/redux";
import { Penalty } from "src/components";
import "./ViewCoursePage.scss";

export const ViewCoursePage = () => {
  const params = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [Seplessons, setSepLessons] = useState<ILesson[]>([]);
  const [Octlessons, setOctLessons] = useState<ILesson[]>([]);
  const [Novlessons, setNovLessons] = useState<ILesson[]>([]);
  const [penalties, setPenalties] = useState<IPenalty[]>([]);
  const [lessonCompleted, setLessonCompleted] = useState<any>([]);
  const [User_feeds, setUser_feeds] = useState<User_feeds[]>([]);
  const [overallHomework, setOverallHomework] = useState<OverallHomework[]>([]);
  const [octOverallHomework, setOctOverallHomework] = useState<OverallHomework[]>([]);
  const [novOverallHomework, setNovOverallHomework] = useState<OverallHomework[]>([]);
  const user = useAppSelector((store) => store.user.data);
  const today = new Date();
  const [activeTab, setActiveTab] = useState('1');
  const dateRanges = [
    {
      start: new Date("2024-09-09"),
      end: new Date("2024-10-08"),
    },
    {
      start: new Date("2024-10-09"),
      end: new Date("2024-11-08"),
    },
    {
      start: new Date("2024-11-09"),
      end: new Date("2024-12-08"),
    },
  ];
  useEffect(() => {
    const currentTabIndex = dateRanges.findIndex(
      (range) => today >= range.start && today <= range.end
    );
    if (currentTabIndex !== -1) {
      setActiveTab(''+ (currentTabIndex + 1)); // Set the active tab index based on the current date
    }
  }, []);

  function getCompletedLessons() {
    axios
      .get(`api/v1/courses/${params?.id}/lesson_completed_count`, {
        headers: authHeader(),
      })
      .then((res: any) => setLessonCompleted(res?.data));
  }
 const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  console.log("Tab changed to: ", newValue); // Check if the function is triggered
  setActiveTab(newValue);
};
  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
    });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-09-09&to_date=2024-10-09&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setSepLessons(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-10-09&to_date=2024-11-08&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setOctLessons(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-11-09&to_date=2024-12-12&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setNovLessons(response.data);
      });  
    axios
        .get("/api/v1/penalty/?skip=0&user_id=" + user.id + "&course_id=" +
            params.id, { headers: authHeader() })
        .then((response) => {
          setPenalties(response.data);
      });
    axios
      .get(
        "/api/v1/user_feed/?skip=0&user_id=" +
          user.id +
          "&course_id=" +
          params.id,
        { headers: authHeader() }
      )
      .then((response) => {
        setUser_feeds(response.data);
      });
    getCompletedLessons();
  }, []);
  

  useEffect(() => {
    if(course && course.price !== 10){
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2022-09-09&to_date=2024-12-09&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setLessons(response.data);
      });
    }
  }, [course]);  

  const hasPremiumAccess = User_feeds.some(
        (feed) => feed.start_date === "2024-10-09" && feed.package === "premium"
      );
  useEffect(() => {
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-09-09&end_date=2024-10-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setOverallHomework(response.data);
      });
    }, []);
  useEffect(() => {
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-10-09&end_date=2024-11-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setOctOverallHomework(response.data);
      });
    }, []); 
    useEffect(() => {
      axios
      .get("/api/v1/homework/overall_lessons_homeworks?start_date=2024-11-09&end_date=2024-12-08&course_id=" + params.id, {
        headers: authHeader(),
      })
      .then((response) => {
        setNovOverallHomework(response.data);
      });
    }, []);    
  const filterPenaltiesForTab = (rangeIndex: number) => {
      const { start, end } = dateRanges[rangeIndex];
      return penalties.filter((penalty) => {
        const createdAt = new Date(penalty.created_at);
        return createdAt >= start && createdAt <= end;
      });
  };
  return (
    <div className="view-course">
      {course ? (
          <div className="course-info box">
            <div className="course-info__top">
              <span className="course-info__heading">
                {course.name}
              </span>
              <div className="course-info__right">
                <Link className="back-btn" to="/my-courses">
                  <i className="fa-solid fa-arrow-left"></i>Назад
                </Link>
              </div>
            </div>  
            <div className="course-info__bot">
              <a
                  target="_blank"
                  rel="noreferrer"
                  href={course.tg_chat_id}
                  className="btn btn-primary btn-telegram btn-flex"
                >
                  Телеграм группа
                  <img src="assets/svg/telegram.svg" />
                </a>
                {User_feeds.length && User_feeds[0]['package'] == 'premium' ? 
                  <Link to={`/course_${course.id}/analytics`} className="btn btn-primary btn-flex">
                    Аналитика ошибок <i className="fa-solid fa-angle-right"></i>
                  </Link>
                  : ""
                }
            </div>
          </div>
        
        ) : (
        ""
      )}
      <div className="last-lesson">
         <>
            {
              course && course.price == 10 ? (
                <TabContext value={activeTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Сентябрь" value="1" />
                      <Tab label="Октябрь" value="2" />
                      <Tab label="Ноябрь" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel className="lesson-panel" value="1">
                    <>
                      <div className="month-info">
                        <div className="month-info__row course-penalty">
                          {
                            <Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={filterPenaltiesForTab(0)}/>
                          }
                        </div>
                        <div>
                          Общий процент дз: {overallHomework.length > 0 && overallHomework[0].homework_is_correct_percent ? 
                              `${overallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                            : "0%"
                            }
                        </div>
                      </div> 
                      {User_feeds.filter((e) => e.start_date === "2024-09-09").length > 0
                        ? 
                        (<ul className="lesson-list"> 
                          {Seplessons.map((lesson, index) =>
                            lesson.start_date ? (
                                <li key={lesson.id}>
                                  <MyLessonItem
                                    lesson={lesson}
                                    index={index}
                                    without_penalty={course ? course.without_penalty : false}
                                    completed={lessonCompleted}
                                  />
                                </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>)   
                        : "Доступов за сентябрь нету"}
                    </>
                  </TabPanel>
                  <TabPanel className="lesson-panel" value="2">
                    {User_feeds.filter((e) => e.start_date === "2024-10-09").length > 0  ?
                      <>
                        <div className="month-info">
                          <div className="month-info__row course-penalty">
                            {
                              <Penalty package_feed={hasPremiumAccess ? "premium" : "standart"} penalties={filterPenaltiesForTab(1)}/>
                            }
                          </div>
                          <div>
                            Общий процент дз: {octOverallHomework.length > 0 && octOverallHomework[0].homework_is_correct_percent ? 
                                `${octOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                              : "0%"
                              }
                          </div>
                        </div>
                        <ul className="lesson-list"> 
                          {Octlessons.map((lesson, index) =>
                            lesson.start_date ? (
                                <li key={lesson.id}>
                                  <MyLessonItem
                                    lesson={lesson}
                                    index={index}
                                    without_penalty={course ? course.without_penalty : false}
                                    completed={lessonCompleted}
                                  />
                                </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </>  
                      : (
                        <div className="box">
                            <p>Загрузка или нету доступов</p>  
                        </div>
                      )
                    }
                  </TabPanel>
                  <TabPanel className="lesson-panel" value="3">
                    {User_feeds.filter((e) => e.start_date === "2024-11-09").length > 0  ?
                      <>
                        <div className="month-info">
                          <div className="month-info__row course-penalty">
                            {
                              <Penalty package_feed={hasPremiumAccess ? "premium" : "standart"} penalties={filterPenaltiesForTab(2)}/>
                            }
                          </div>
                          <div>
                            Общий процент дз: {octOverallHomework.length > 0 && octOverallHomework[0].homework_is_correct_percent ? 
                                `${octOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                              : "0%"
                              }
                          </div>
                        </div>
                        <ul className="lesson-list"> 
                          {Novlessons.map((lesson, index) =>
                            lesson.start_date ? (
                                <li key={lesson.id}>
                                  <MyLessonItem
                                    lesson={lesson}
                                    index={index}
                                    without_penalty={course ? course.without_penalty : false}
                                    completed={lessonCompleted}
                                  />
                                </li>
                            ) : (
                              ""
                            )
                          )}
                        </ul>
                      </>  
                      : (
                        <div className="box">
                            <p>Загрузка или нету доступов</p>  
                        </div>
                      )
                    }
                  </TabPanel>
                </TabContext>
              ) : 
              (
                <div className="lesson-panel box">
                    <div className="month-info">
                      <div className="month-info__row course-penalty">
                        {
                          <Penalty package_feed={User_feeds.length ? User_feeds[0]['package'] : "standart"} penalties={filterPenaltiesForTab(0)}/>
                        }
                      </div> 
                      <div>
                        Общий процент дз: {novOverallHomework.length > 0 && novOverallHomework[0].homework_is_correct_percent ? 
                            `${novOverallHomework[0].homework_is_correct_percent.toFixed(0)}%` 
                          : "0%"
                          }
                      </div>
                    </div>
                    {lessons.length > 0 ?
                      <ul className="lesson-list"> 
                        {lessons.map((lesson, index) =>
                          lesson.start_date ? (
                              <li key={lesson.id}>
                                <MyLessonItem
                                  lesson={lesson}
                                  index={index}
                                  without_penalty={course ? course.without_penalty : false}
                                  completed={lessonCompleted}
                                />
                              </li>
                          ) : ""
                        )}
                      </ul> 
                      :  (
                        <p>Уроки скоро будут загружены</p>
                      )
                    } 
                     
                </div>
              )
            }
            </>
      </div>
    </div>
  );
};
